// @ts-strict-ignore
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { Staatsangehoerigkeiten } from '@shared/constants/shared-constants';
import { DetailsucheRequest } from '@shared/models/detailsucheRequest';
import { AdressTypEnum, WaehlerHerkunft, WahlkartenStatusEnum } from '@shared/models/enums';
import { GebaeudesucheErgebnis } from '@shared/models/gebaeudesucheErgebnis';
import { PaginatedResult } from '@shared/models/paginatedResult';
import { SearchGebaeudeResponse } from '@shared/models/searchGebaeudeResponse';
import { Wahldaten } from '@shared/models/wahldaten';
import { WahlvorbereitungInfo } from '@shared/models/wahlvorbereitungInfo';
import { Zustelladresse } from '@shared/models/zustelladresse';
import { DateUtilsService } from '@shared/services/date-utils.service';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { AddWaehlerRequest } from '../models/addWaehlerRequest';
import {
    WahlbezugsadresseEnum,
    ZewaerUebermittlungAktionFilter,
    ZewaerUebermittlungStatusFilter
} from '../models/enums';
import { ProtokollEintrag } from '../models/protokollEintrag';
import { RegisterStatusResponse } from '../models/registerStatusResponse';
import { SearchWahlberechtigtePersonenRequest } from '../models/searchWahlberechtigtePersonenRequest';
import { SearchWahlberechtigtePersonenResponse } from '../models/searchWahlberechtigtePersonenResponse';
import { Staatsangehoerigkeit } from '../models/staatsangehoerigkeit';
import { UINutzungseinheit } from '../models/uINutzungseinheit';
import { UpdateAnmerkungRequest } from '../models/updateAnmerkungRequest';
import { UpdateKontaktdatenRequest } from '../models/updateKontaktdatenRequest';
import { UpdatePersonendatenRequest } from '../models/updatePersonendatenRequest';
import { UpdateUnterstuetzungserklaerungRequest } from '../models/updateUnterstuetzungserklaerungRequest';
import { UpdateVorgezogeneStimmabgabeRequest } from '../models/updateVorgezogeneStimmabgabeRequest';
import { WaehlerDaten } from '../models/waehlerDaten';
import { WahlberechtigtePerson } from '../models/wahlberechtigtePerson';
import { WahlberechtigtePersonDetail } from '../models/wahlberechtigtePersonDetail';
import { WahlbestandExcelRequest } from '../models/wahlbestandExcelRequest';
import { WahlbestandResponse } from '../models/wahlbestandResponse';
import { WahlinfoFreigabeRequest } from '../models/wahlinfoFreigabeRequest';
import { ZewaerUebermittlungDaten } from '../models/zewaerUebermittlungDaten';
import { WahlUiConstants } from '../models/wahlUiConstants';

@Injectable({
    providedIn: 'root'
})
export class WaehlerverzeichnisService {
    wahlvorbereitungBaseUrl: string = null;
    adressenBaseUrl: string = null;

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigAssetLoaderService
    ) {
        this.wahlvorbereitungBaseUrl = this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl;
        this.adressenBaseUrl = `${this.configService.getConfig().k5civisAdr.adressenApiUrl}`;
    }

    // #region Wahl bearbeiten

    /**
     * Lädt alle Wahlen eines Mandanten => MandantId im HTTP-Header.
     * @returns Wahlen
     */
    getWahlen(): Observable<Wahldaten[]> {
        return this.httpClient.get<Wahldaten[]>(`${this.wahlvorbereitungBaseUrl}/Wahl`);
    }

    /**
     * Lädt die Wahl mit der technischen Id eines Mandanten
     * @param Id Technische Id der Wahl
     * @returns Wahl
     */
    getWahlById(id: string): Observable<Wahldaten> {
        return this.httpClient.get<Wahldaten>(`${this.wahlvorbereitungBaseUrl}/Wahl/${id}`);
    }

    /**
     * Lädt die Informationen der Wahlvorbereitung
     * @returns Wahlvorbereitungsinformationen
     */
    getWvInfoById(wahlId: string): Observable<WahlvorbereitungInfo> {
        return this.httpClient.get<WahlvorbereitungInfo>(`${this.wahlvorbereitungBaseUrl}/Wahlstatus/${wahlId}`);
    }

    // #endregion Wahl bearbeiten

    // #region Wahlbestand bearbeiten

    /**
     * Lädt das eWählerverzeichnis eines Mandanten als *.csv-Datei hoch.
     * Auftretende Fehler werden geparst und in einen string konvertiert.
     * @param wahlId ID der Wahl
     * @param formdata FormData mit der *.csv-Datei des eWählerverzeichnisses
     * @returns Observable<string> Fehlernachrichten
     */
    uploadWvzFile(wahlId: string, formdata: FormData): Observable<string | HttpEvent<string>> {
        return this.httpClient.post<string>(`${this.wahlvorbereitungBaseUrl}/ImportWahlbestand/${wahlId}`, formdata, {
            reportProgress: true,
            observe: 'events'
        });
    }

    /**
     * Prüft, ob der ASV-Import läuft und ermöglicht den Neustart des Imports, wenn der vorherige Aufruf zu lange dauert
     * @remarks Notwendig, weil der ASV-Import ansonsten unendlich laufen würde, wenn kein Benutzer erneut importiert
     * @param wahlId ID der Wahl
     * @returns Observable<void> ASV Import Status wurde erfolgreich aktualisiert.
     */
    refreshVorgezogeneStimmabgabenImportStatus(wahlId: string): Observable<void> {
        return this.httpClient.post<void>(
            `${this.wahlvorbereitungBaseUrl}/wahlen/${wahlId}/RefreshASVImportStatus`,
            null
        );
    }

    /**
     * Lädt die vorgezogenen Stimmabgaben aus dem ASV zu einer Wahl als *.xml-Datei hoch.
     * Auftretende Fehler werden geparst und in einen string konvertiert.
     * @param wahlId ID der Wahl
     * @param formdata FormData mit der *.xml-Datei
     * @returns Observable<string> Fehlernachrichten
     */
    uploadASVVorgezogeneStimmabgabenFile(wahlId: string, formdata: FormData): Observable<string | HttpEvent<string>> {
        return this.httpClient.post<string>(`${this.wahlvorbereitungBaseUrl}/wahlen/${wahlId}/ImportASV`, formdata, {
            reportProgress: true,
            observe: 'events'
        });
    }

    /**
     * Download des Protokolls zum letzten Import von vorgezogenen Stimmabgaben.
     * @param wahlId ID der Wahl
     */
    downloadVorgezogeneStimmabgabenProtokoll(wahlId: string): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(`${this.wahlvorbereitungBaseUrl}/wahlen/${wahlId}/ReadASVImportprotokoll`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    /**
     * Initiales laden eines Wählerverzeichnises mit initialer Sortierung und Paging
     * @param wahlId Id der Wahl
     * @param pageNumber Seitennummer
     * @param pageSize Anzahl Ergebnisse pro Seite
     * @param sortBy Attribut für die Sortierung
     * @param sortDescending Gibt an ob absteigend oder aufsteigend sortiert wird
     */
    getWahlbestand(
        wahlId: string,
        pageNumber: number = 0,
        pageSize: number = 200,
        sortBy: string = '',
        sortDescending: boolean = false
    ): Observable<WahlbestandResponse> {
        const params = new HttpParams()
            .set('pageSize', pageSize.toString())
            .set('pageNumber', pageNumber.toString())
            .set('sortBy', sortBy)
            .set('sortDescending', sortDescending);

        return this.httpClient.get<WahlbestandResponse>(`${this.wahlvorbereitungBaseUrl}/Wahlbestand/${wahlId}`, {
            params
        });
    }

    /**
     * sendet Post-Request an das Backend um eine Wahlbestandsliste basierend auf einer Detailsuche zu erhalten
     * @param request DetailsucheRequest
     */
    getWahlbestandFromDetailsuche(request: DetailsucheRequest) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<WahlbestandResponse>(
            `${this.wahlvorbereitungBaseUrl}/Wahlbestand/Detailsuche`,
            request,
            { headers }
        );
    }

    /**
     * Aufruf zum Erstellen des Wahlbestands für die ausgewählte Wahl.
     * @param wahlId Id der Wahl
     * @returns
     */
    createWahlbestand(wahlId: string): Observable<unknown> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<unknown>(`${this.wahlvorbereitungBaseUrl}/WahlbestandErstellen/${wahlId}`, {
            headers
        });
    }

    /**
     * Aufruf für die Prüfung des Wahlbestands
     * @returns
     */
    pruefungWahlbestand(wahlId: string): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<string>(`${this.wahlvorbereitungBaseUrl}/WahlbestandFreigeben/${wahlId}/pruefung`, {
            headers
        });
    }

    /**
     * Gibt den Wahlbestand nach der Prüfung und Freigabe durch den Benutzer frei
     * Status, Protokoll und Anmerkung des Benutzers werden aktualisiert.
     * @returns
     */
    freigabeWahlbestand(wahlId: string): Observable<unknown> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<unknown>(
            `${this.wahlvorbereitungBaseUrl}/WahlbestandFreigeben/${wahlId}/freigabe`,
            {
                headers
            }
        );
    }

    /**
     * Aufruf für die Prüfung des Wahlbestands
     * @returns
     */
    abbruchFreigabeWahlbestand(wahlId: string): Observable<unknown> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<unknown>(`${this.wahlvorbereitungBaseUrl}/WahlbestandFreigeben/${wahlId}/abbruch`, {
            headers
        });
    }

    /**
     * Führt die Druckfreigabe der Wahlinformation für den zentralen Druck durch.
     * Dabei werden die Wahlinformationen mit den Einstellungen des Request erstellt
     * und zusammen mit Wahl-, Mandant- und Sprengelinformationen an die Druckerei gesendet.
     * @param wahlId ID der Wahl
     * @param request WahlinfoFreigabeRequest
     * @returns
     */
    druckfreigabeWahlinformation(wahlId: string, request: WahlinfoFreigabeRequest): Observable<void> {
        return this.httpClient.post<void>(
            `${this.wahlvorbereitungBaseUrl}/WahlinformationDruckfreigabe/${wahlId}`,
            request
        );
    }

    /**
     * Überträgt die behobenen Klärungsadressen des Wahlbestands an das ZeWaeR.
     * @param wahlId Id der Wahl
     * @returns string Durchführungsmeldung
     */
    transmitKlaerungsadressenToZeWaeR(wahlId: string): Observable<unknown> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<unknown>(
            `${this.wahlvorbereitungBaseUrl}/Wahlbestand/${wahlId}/KlaerungsadressenZeWaeR`,
            { headers }
        );
    }

    // #endregion Wahlbestand bearbeiten

    // #region Wähler bearbeiten

    /**
     * Lädt die Details zu einer wahlberechtigten Person
     * @param id WahlbestandEntity Id
     */
    getWaehlerById(id: string): Observable<WaehlerDaten> {
        return this.httpClient.get<WaehlerDaten>(`${this.wahlvorbereitungBaseUrl}/Waehler/${id}`);
    }

    /**
     * Lädt die Details zu einer wahlberechtigten Person inkl. Wahl, Wahlkarte und Sonderwahlbehörde
     * @param wahlId Id der Wahl
     * @param wahlbestandId Id der wahlberechtigten Person
     * @returns Detailinfos zur wahlberechtigten Person
     */
    getWahlberechtigtePersonDetailById(wahlId: string, wahlbestandId: string): Observable<WahlberechtigtePersonDetail> {
        return this.httpClient.get<WahlberechtigtePersonDetail>(
            `${this.wahlvorbereitungBaseUrl}/Wahlen/${wahlId}/Wahlbestand/${wahlbestandId}`
        );
    }

    /**
     * Lädt die Liste der Protokolleinträge einer wahlberechtigten Person
     * @param id WahlbestandId id
     * @returns ProtokollEntries[] response
     */
    getWaehlerProtokoll(id: string): Observable<ProtokollEintrag[]> {
        return this.httpClient.get<ProtokollEintrag[]>(`${this.wahlvorbereitungBaseUrl}/Waehler/${id}/Protokoll`);
    }

    /**
     * Aufruf zur Streichung einer wahlberechtigten Person aus dem Wahlbestand
     * @param id Id des Wählers
     * @param bemerkung Begruendung für die Streichung
     * @returns Waehler
     */
    personStreichen(id: string, bemerkung: string): Observable<WaehlerDaten> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<WaehlerDaten>(
            `${this.wahlvorbereitungBaseUrl}/Waehler/${id}/streichen`,
            JSON.stringify(bemerkung),
            { headers }
        );
    }

    /**
     * Aufruf zum 'verstorben' Markieren einer wahlberechtigten Person aus dem Wahlbestand
     * @param id Id des Wählers
     * @param bemerkung Begruendung für die 'verstorben' Markierung
     * @returns Waehler
     */
    personVerstorben(id: string, bemerkung: string): Observable<WaehlerDaten> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<WaehlerDaten>(
            `${this.wahlvorbereitungBaseUrl}/Waehler/${id}/verstorben`,
            JSON.stringify(bemerkung),
            { headers }
        );
    }

    /**
     * Korrigiert die Personendaten eines Wählers
     * @param id Id der Person im Wahlbestand
     * @param personendaten Daten zur Korrektur
     * @returns WaehlerDaten der korrigierten Person
     */
    personKorrigieren(id: string, personendaten: UpdatePersonendatenRequest): Observable<WaehlerDaten> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<WaehlerDaten>(`${this.wahlvorbereitungBaseUrl}/Waehler/${id}`, personendaten, {
            headers
        });
    }

    /**
     * Bearbeitet die Kontaktdaten eines Wählers
     * @param id Id der Person im Wahlbestand
     * @param kontaktdaten Daten zur Korrektur
     * @returns WaehlerDaten der bearbeiteten Person
     */
    kontaktdatenBearbeiten(id: string, kontaktdaten: UpdateKontaktdatenRequest): Observable<WaehlerDaten> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<WaehlerDaten>(
            `${this.wahlvorbereitungBaseUrl}/Waehler/${id}/Kontaktdaten`,
            kontaktdaten,
            {
                headers
            }
        );
    }

    /**
     * Aktualisiert die Unterstützungserklärung eines Wählers
     * @param id Id der Person im Wahlbestand
     * @param unterstuetzungserklaerungData Daten zum Setzen der Erklärung
     * @returns WaehlerDaten der bearbeiteten Person
     */
    updateUnterstuetzungserklaerung(
        id: string,
        unterstuetzungserklaerungData: UpdateUnterstuetzungserklaerungRequest
    ): Observable<WaehlerDaten> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<WaehlerDaten>(
            `${this.wahlvorbereitungBaseUrl}/Waehler/${id}/Unterstuetzungserklaerung`,
            unterstuetzungserklaerungData,
            {
                headers
            }
        );
    }

    /**
     * Bearbeitet die Anmerkung einer wahlberechtigten Person
     * @param wahlId Id der Wahl
     * @param wahlbestandId Id der Person im Wahlbestand
     * @param anmerkung Neue Anmerkung als string
     * @returns WaehlerDaten der bearbeiteten Person
     */
    personAnmerkungBearbeiten(
        wahlId: string,
        wahlbestandId: string,
        anmerkungRequest: UpdateAnmerkungRequest
    ): Observable<WaehlerDaten> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<WaehlerDaten>(
            `${this.wahlvorbereitungBaseUrl}/Wahlen/${wahlId}/Wahlbestand/${wahlbestandId}/Anmerkung`,
            anmerkungRequest,
            { headers }
        );
    }

    /**
     * Löscht die Zustelladresse eines Wählers
     * @param waehlerId Id des Wählers
     * @returns Waehler Daten des Wählers
     */
    deleteZustelladresse(waehlerId: string): Observable<WaehlerDaten> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.delete<WaehlerDaten>(
            `${this.wahlvorbereitungBaseUrl}/Waehler/${waehlerId}/Zustelladresse`,
            { headers }
        );
    }

    /**
     * Legt fest, ob eine Person am vorgezogenen Wahltag abgestimmt hat.
     * @param waehlerId Id des Wählers
     * @param vorgezogeneStimmabgabe Daten zum setzen der vorgezogenen Stimmabgabe
     * @returns Daten des Wählers
     */
    updateVorgezogeneStimmabgabe(
        waehlerId: string,
        vorgezogeneStimmabgabe: UpdateVorgezogeneStimmabgabeRequest
    ): Observable<WaehlerDaten> {
        return this.httpClient.post<WaehlerDaten>(
            `${this.wahlvorbereitungBaseUrl}/Waehler/${waehlerId}/VorgezogeneStimmabgabe`,
            vorgezogeneStimmabgabe
        );
    }

    // #endregion Wähler bearbeiten

    // #region Wahlberechtigte Personen suchen

    /**
     * Sucht Personen im ZMR und ERnP anhand der übergebenen Suchkriterien.
     * Gestrichene Personen werden markiert geliefert, sodass diese im Wahlbestand
     * wieder reaktiviert werden können.
     * @param vorname Vorname der Person
     * @param familienname Familienname der Person
     * @param geburtsdatum Geburtsdatum der Person
     * @param bpkzp Bpk-ZP der Person
     * @param wahlId Id der Wahl
     * @returns Personen aus dem ZMR und ERnP
     */
    searchWahlberechtigtePersonen(
        vorname: string,
        familienname: string,
        geburtsdatum: DateTime,
        bpkzp: string,
        wahlId: string
    ): Observable<SearchWahlberechtigtePersonenResponse> {
        const searchWahlberechtigtePersonenRequest: SearchWahlberechtigtePersonenRequest = {
            wahlId: wahlId,
            begruendungZMRZugriff: 'Wählerverzeichnis erweitern',
            familienname: familienname,
            vorname: vorname,
            geburtsdatum: DateUtilsService.convertLuxonDateTimeToDate(geburtsdatum),
            bpkzp: bpkzp
        };

        return this.httpClient.post<SearchWahlberechtigtePersonenResponse>(
            `${this.wahlvorbereitungBaseUrl}/Waehler/SucheWahlberechtigt`,
            searchWahlberechtigtePersonenRequest
        );
    }

    /**
     * Sucht eine Person im ZMR und ERnP für den Personenabgleich
     * @param bpkzp Bpk-ZP der Person
     * @param familienname Familienname der Person
     * @returns Personendaten aus dem ZMR oder ERnP
     */
    searchPersonInZMR(bpkzp: string, familienname: string): Observable<WahlberechtigtePerson> {
        const searchWahlberechtigtePersonenRequest: SearchWahlberechtigtePersonenRequest = {
            begruendungZMRZugriff: 'Wählerverzeichnis Korrektur',
            familienname: familienname,
            bpkzp: bpkzp
        };

        return this.httpClient.post<WahlberechtigtePerson>(
            `${this.wahlvorbereitungBaseUrl}/Waehler/SucheZMR`,
            searchWahlberechtigtePersonenRequest
        );
    }

    /**
     * Nimmt eine Person im eWVZ auf
     */
    personAufnehmen(
        wahlId: string,
        person: WahlberechtigtePerson,
        gebaeude: GebaeudesucheErgebnis,
        nutzungseinheit: UINutzungseinheit,
        zustelladresse: Zustelladresse,
        wahlkartenabo: boolean,
        aufnahme: WaehlerHerkunft,
        begruendung: string
    ): Observable<unknown> {
        const waehlerRequest: AddWaehlerRequest = {
            wahlId,
            waehler: this.buildWaehlerDaten(person, gebaeude, nutzungseinheit, zustelladresse, wahlkartenabo, aufnahme),
            begruendung: begruendung
        };

        return this.httpClient.post(`${this.wahlvorbereitungBaseUrl}/Waehler`, waehlerRequest);
    }

    /**
     * Reaktiviert eine Person im Wahlbestand
     * @param wahlbestandId Id der wahlberechtigten Person
     */
    personReaktivieren(wahlbestandId: string): Observable<unknown> {
        return this.httpClient.post(`${this.wahlvorbereitungBaseUrl}/Waehler/${wahlbestandId}/Reaktivieren`, null);
    }

    /**
     * Setzt das Wähler Daten Objekt
     * @returns Daten des Wählers
     */
    buildWaehlerDaten(
        person: WahlberechtigtePerson,
        gebaeude: GebaeudesucheErgebnis,
        nutzungseinheit: UINutzungseinheit,
        zustelladresse: Zustelladresse,
        wahlkartenabo: boolean,
        aufnahme: WaehlerHerkunft
    ): WaehlerDaten {
        const staatsangehoerigkeit: Staatsangehoerigkeit = this.getCorrespondingStaatsangehoerigkeit(person);

        // Der Adresstyp der Wahlbezugsadresse wird wie folgt gesetzt:
        // - Ist die Person Obdachlos wird der Typ auf WahlbezugsadresseEnum.Obdachlos gesetzt
        // - Ist die Person inhaftiert wird der Typ auf WahlbezugsadresseEnum.Sprengeladresse gesetzt (damit sie nicht zu den HWS gerechnet wird)
        // - Hat die Person nur einen Nebenwohnsitz in der Gemeinde so wird der Typ auf WahlbezugsadresseEnum.Nebenwohnsitz gesetzt (Nur in Burgenland relevant)
        // - Sonst wird der Adresstyp auf WahlbezugsadresseEnum.Wahlbezugsadresse gesetzt
        let wahlbezugsadresseTyp = WahlbezugsadresseEnum.Wahlbezugsadresse;
        if (person?.hochwertigsteAdresse?.typ === AdressTypEnum.ZMRObdachlos) {
            wahlbezugsadresseTyp = WahlbezugsadresseEnum.Obdachlos;
        } else if (person?.inhaftiert) {
            wahlbezugsadresseTyp = WahlbezugsadresseEnum.Sprengeladresse;
        } else if (person?.hochwertigsteAdresse?.typ === AdressTypEnum.ZMRNebenwohnsitz) {
            wahlbezugsadresseTyp = WahlbezugsadresseEnum.Nebenwohnsitz;
        }

        const waehlerDaten: WaehlerDaten = {
            id: WahlUiConstants.GUID_EMPTY,
            akadGradPost: person?.akadGradNach,
            akadGradPre: person?.akadGradVor,
            anmerkung: null,
            antragscode: null,
            bpK_ZP: person?.bpkzp,
            // Doppelgänger wird aktuell nicht befüllt
            doppelgaenger: false,
            familienname: person?.familienname,
            geburtsdatum: person?.geburtsdatum,
            geloescht: false,
            gestrichen: false,
            lfdNr: null,
            // Wird aktuell nicht befüllt
            mailadresse: null,
            sprNr: gebaeude?.sprengelNummer,
            // Sprengeltext vom ZeWaeR
            sprengel: null,
            staatsang: staatsangehoerigkeit?.isoCode3,
            staatsangText: staatsangehoerigkeit?.staatsname,
            verstorben: person?.verstorben,
            vorname: person?.vorname,
            wA_ADRCD: gebaeude?.adresscode?.toString(),
            wA_Adresstyp: wahlbezugsadresseTyp,
            wA_Hausnummer: `${gebaeude?.orientierungsnummer ? gebaeude?.orientierungsnummer : ''}`,
            wA_NtzLNr: nutzungseinheit?.ntzLNr?.toString(),
            wA_ObjNr: gebaeude?.objektnummer?.toString(),
            wA_Ort: gebaeude?.ortschaftsname,
            wA_Plz: gebaeude?.plz?.toString(),
            wA_SUBCD: gebaeude?.subcode?.toString(),
            wA_Stiege: `${gebaeude?.restadresse ? gebaeude?.restadresse : ''}`,
            wA_Strasse: gebaeude?.strassenname,
            wA_Tuer: nutzungseinheit?.ntzAdressteil,
            wA_Gemeinde: gebaeude?.gemeindename,
            waehlerHerkunft: aufnahme,
            // Wähler-Id vom ZeWaeR
            waehlerId: null,
            wahlausschluss: false,
            wahlkarte: false,
            wahlkarteStatus: WahlkartenStatusEnum.Keine,
            wahlkartenAbo: wahlkartenabo,
            // Wird im Backend festgelegt
            zA_Adresstyp: null,
            zA_Anschriftszeile1: zustelladresse?.zA_Anschriftszeile1,
            zA_Anschriftszeile2: zustelladresse?.zA_Anschriftszeile2,
            zA_Anschriftszeile3: zustelladresse?.zA_Anschriftszeile3,
            zA_Anschriftszeile4: zustelladresse?.zA_Anschriftszeile4,
            zA_Anschriftszeile5: zustelladresse?.zA_Anschriftszeile5,
            zA_Anschriftszeile6: zustelladresse?.zA_Anschriftszeile6,
            zA_Staat: zustelladresse?.zA_Staat,
            zA_StaatText: zustelladresse?.zA_StaatText,
            zmR_Id: person?.zmrZahl,
            haftkennzeichen: person?.inhaftiert
        };
        return waehlerDaten;
    }

    // #endregion Wahlberechtigte Personen suchen

    // #region ZeWaeR Übermittlungen

    /**
     * Liefert den Status der Verbindung zum ZeWaeR für den angemeldeten Benutzer, dessen Mandanteneinstellungen
     * und der ausgewählten Wahl zurück.
     * @returns Status der ZeWaeR-Verbindung
     */
    getZeWaeRStatus(wahlId: string): Observable<RegisterStatusResponse> {
        return this.httpClient.get<RegisterStatusResponse>(`${this.wahlvorbereitungBaseUrl}/Zewaer/${wahlId}`);
    }

    /**
     * Liefert Daten der Übermittlungen einer Wahl für die Darstellung im Grid
     * @remarks Parameter 'suchstring' wird erst in Sprint 59 implementiert
     * @param wahlId Id der Wahl
     * @param pageSize Anzahl der Datensätze pro Seite
     * @param pageNumber Aktuelle Seitennummer der geladenen Daten
     * @param suchstring Optionale Einschränkung nach Benutzer bzw. Wählernamen
     * @param sortBy Gibt an, welche Sortierung angewandt werden soll
     * @param sortDescending Gibt die Sortierreihenfolge an
     * @param statusFilters Optionale Einschränkung nach Status
     * @param aktionFilters Optionale Einschränkung nach Aktion
     * @returns Daten der Übermittlungen einer Wahl für die Darstellung im Grid
     */
    getZewaerUebermittlungen(
        wahlId: string,
        pageSize: number,
        pageNumber: number,
        suchstring: string,
        sortBy: string,
        sortDescending: string,
        statusFilters: ZewaerUebermittlungStatusFilter[],
        aktionFilters: ZewaerUebermittlungAktionFilter[]
    ): Observable<PaginatedResult<ZewaerUebermittlungDaten>> {
        let params = new HttpParams()
            .set('pageSize', pageSize)
            .set('pageNumber', pageNumber)
            .set('suchstring', suchstring)
            .set('sortBy', sortBy)
            .set('sortDescending', sortDescending === 'desc');

        statusFilters.forEach((statusFilter: ZewaerUebermittlungStatusFilter) => {
            params = params.append('statusFilters', statusFilter);
        });

        aktionFilters.forEach((aktionFilter: ZewaerUebermittlungAktionFilter) => {
            params = params.append('aktionFilters', aktionFilter);
        });

        return this.httpClient.get<PaginatedResult<ZewaerUebermittlungDaten>>(
            `${this.wahlvorbereitungBaseUrl}/Wahl/${wahlId}/ZewaerUebermittlungen`,
            { params }
        );
    }

    /**
     * Wiederholt die Übertragung an das ZeWaeR für einen ausgewählten Eintrag.
     * @param id Id der ZeWaeR-Übermittlungsdaten
     * @returns No Content
     */
    repeatZewaerUebermittlung(id: string): Observable<unknown> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<unknown>(
            `${this.wahlvorbereitungBaseUrl}/ZewaerUebermittlungen/${id}/Wiederholen`,
            {
                headers
            }
        );
    }

    /**
     * Ermittelt fehlende ZeWaeR-Übermittlungen der Sprengelnummer-Korrekturen und Wahlkarten-Übermittlungen und
     * stößt die erneute Übermittlung aller offenen ZeWaeR-Übermittlungen der Wahlen eines Mandanten an.
     * Aufgrund PBI 16493 wird die automatische Korrektur nicht mehr angestoßen. Der Code soll aber vorerst verfügbar bleiben.
     * @returns No Content
     */
    correctMissingZewaerUebermittlungen(): Observable<unknown> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<unknown>(
            `${this.wahlvorbereitungBaseUrl}/Wahlen/CorrectMissingZewaerUebermittlungen`,
            {
                headers
            }
        );
    }

    /**
     * Wiederholt die Übertragung an das ZeWaeR für alle offenen Übermittlungen.
     * @returns No Content
     */
    repeatAllOpenZewaerUebermittlungen(wahlId: string): Observable<unknown> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post<unknown>(
            `${this.wahlvorbereitungBaseUrl}/Wahl/${wahlId}/WiederholeAlleZewaerUebermittlungen`,
            { headers }
        );
    }

    // #endregion ZeWaeR Übermittlungen

    // #region Gebäudesuche
    /**
     * Liefert eine Liste der Gebäude zurück
     * @param sprengelgruppeId Id der Sprengelgruppe
     * @param suchString String, nach dem gesucht wird
     * @param seite Seitennummer
     * @param anzahlProSeite Anzahl pro Seite
     * @returns Liste der Gebäude
     */
    searchForGebaeude(
        sprengelgruppeId: string,
        suchString: string,
        seite: number = 1,
        anzahlProSeite: number = 999
    ): Observable<SearchGebaeudeResponse> {
        return this.httpClient.get<SearchGebaeudeResponse>(`${this.adressenBaseUrl}/adressen/gebaeude`, {
            params: new HttpParams()
                .set('SprengelgruppeId', sprengelgruppeId)
                .set('SuchString', suchString)
                .set('Seite', seite.toString())
                .set('AnzahlProSeite', anzahlProSeite.toString())
        });
    }
    // #endregion Gebäudesuche

    // #region Excel Export

    /**
     * Liefert Excel-Daten für einen Waehlerinformation Serienbrief Excel Export
     * @param wahlId Technische Id der Wahl
     * @returns Excel-Daten
     */
    exportWaehlerinformationForSerienbrief(wahlId: string): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(`${this.wahlvorbereitungBaseUrl}/datenExport/${wahlId}/WahlinformationSerienbrief`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    /**
     * Liefert Excel-Daten für einen Waehlerverzeichnis Excel Export
     * @param wahlId Technische Id der Wahl
     * @returns Excel-Daten
     */
    exportWaehlerverzeichnis(wahlId: string): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(`${this.wahlvorbereitungBaseUrl}/datenExport/${wahlId}/Waehlerverzeichnis`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    /**
     * Liefert XML-Daten des Waehlerverzeichnis für das Abstimmungsverzeichnis(ASV)
     * @param wahlId Technische Id der Wahl
     * @returns XML-Daten
     */
    exportASVWaehlerverzeichnis(wahlId: string): Observable<HttpResponse<Blob>> {
        const headers = new HttpHeaders().append('Content-Type', 'application/xml');
        return this.httpClient.get(`${this.wahlvorbereitungBaseUrl}/datenExport/${wahlId}/AsvWaehlerverzeichnis`, {
            headers,
            observe: 'response',
            responseType: 'blob'
        });
    }

    /**
     * Liefert Excel-Daten für eine Wahlbestand Excel Export
     * @param wahlId Technische Id der Wahl
     * @param request Abfrage-Daten für eine Wahlbestand Excel Export
     * @returns Excel-Daten
     */
    exportWahlbestand(wahlId: string, request: WahlbestandExcelRequest): Observable<HttpResponse<Blob>> {
        return this.httpClient.post(`${this.wahlvorbereitungBaseUrl}/datenExport/${wahlId}/Wahlbestand`, request, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    // #endregion

    // #region Hilfsfunktionen

    /**
     * Gibt die relevante Staatsangehörigkeit zurück oder undefined, wenn keine existiert
     * @param person Waehler
     * @returns Relevante Staatsangehörigkeit oder undefined, wenn es keine relevante Staatsangehörigkeit gibt
     */
    getCorrespondingStaatsangehoerigkeit(person: WahlberechtigtePerson): Staatsangehoerigkeit {
        let staatsangehoerigkeit: Staatsangehoerigkeit;
        if (person?.staatsangehoerigkeiten?.length > 0) {
            // Aktuell wird nur die österreichische Staatsbürgerschaft für die Bundespräsidentenwahl herangezogen
            staatsangehoerigkeit = person.staatsangehoerigkeiten.find(
                (stang) => stang.isoCode3 === Staatsangehoerigkeiten.OESTERREICH_ISO3
            );

            // Falls die Person keine österreichische Staatsbügerschaft besitzt wird eine ausländische Staatsbürgerschaft zurückgegeben
            if (!staatsangehoerigkeit) {
                staatsangehoerigkeit = person.staatsangehoerigkeiten[0];
            }
        }
        return staatsangehoerigkeit;
    }

    /**
     * Liefert den zusammengesetzten Namen des Wählers
     * @param waehler Wähler
     */
    getFullName(waehler: WaehlerDaten): string {
        if (!waehler) return '';
        return [waehler.akadGradPre, waehler.vorname, waehler.familienname, waehler.akadGradPost]
            .filter((text) => text)
            .join(' ');
    }

    // #endregion Hilfsfunktionen
}
